@import '~antd/dist/antd.css';
// @import '~antd/dist/antd.dark.css';

html {
  font-family: sans-serif;
  font-size: 12px;
}

body {
  margin: 0;

  .ais-SearchBox-form::before {
    height: 16px;
    width: 16px;
  }
}

html,
body,
#root {
  height: 100%;
  overflow-x: hidden;
}

.calendar .fc .fc-timegrid-slot {
  height: 3rem;
}
